<template>
  <v-container fluid>
    <v-row justify="start" class="ma-2" no-gutters>
      <v-col cols="12">
        <h1>{{ $t("links.title") }}</h1>
      </v-col>

      <!-- Buttons -->
      <v-col cols="12" class="mt-6 text-right">
        <v-btn color="primary" @click="toggleAddDialog(true)">
          {{ $t("links.buttons.add") }}
        </v-btn>
      </v-col>

      <v-col cols="12" class="mt-6">
        <div>
          <v-text-field
            v-model="search"
            append-icon="fas fa-search"
            :label="$t('common.inputs.search')"
            clearable
            v-on:click:clear="clearInput"
            @keyup.enter.native="searchTrigger"
            outlined
          ></v-text-field>
        </div>
      </v-col>

      <!-- Table -->
      <v-col cols="12" class="mt-2">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :loading="loading"
          :items-per-page="20"
          style="min-height: 600px"
          sort-by="createdAt"
          :sort-desc="false"
          :footer-props="{ 'items-per-page-options': itemsPerPage }"
          class="elevation-1"
        >
          <!-- Account -->
          <template v-slot:item.account="{ item }">
            <span v-if="item.account">
              <RouterLink
                :to="{ name: 'Account', params: { id: item.account.id } }"
              >
                {{ item.account.name }}
              </RouterLink>
            </span>
            <span v-else>{{ $t("common.misc.NA") }}</span>
          </template>

          <!-- Product -->
          <template v-slot:item.product="{ item }">
            <span v-if="item.product">
              <RouterLink
                :to="{ name: 'Product', params: { id: item.product.id } }"
              >
                {{ item.product.name }}
              </RouterLink>
            </span>
            <span v-else>{{ $t("common.misc.NA") }}</span>
          </template>

          <!-- Static Link -->
          <template v-slot:item.static_link="{ item }">
            <a :href="item.static_link">{{ item.static_link }}</a>
            <ButtonIconCopy :value="item.static_link" />
          </template>

          <!-- Dynamic link -->
          <template v-slot:item.dynamic_link="{ item }">
            <a :href="item.dynamic_link">{{ item.dynamic_link }}</a>
            <ButtonIconCopy :value="item.dynamic_link" />
          </template>

          <!-- Enabled -->
          <template v-slot:item.enabled="{ item }">
            <IconCheckOrTimes :enabled="item.enabled" />
          </template>

          <!-- Actions -->
          <template v-slot:item.actions="{ item }">
            <!-- View -->
            <BtnIconDetails @click="toggleDetailsDialog(true, item)" />

            <!-- Edit -->
            <BtnIconEdit @click="toggleEditDialog(true, item)" />

            <!-- Delete -->
            <BtnIconDelete @click="toggleDeleteDialog(true, item)" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Main qr code dialog -->
    <LinkMainDialog
      v-if="showDialog.addOrEdit"
      :value="showDialog.addOrEdit"
      :item="currentQrCode"
      @close="(needRefresh) => toggleEditDialog(false, null, needRefresh)"
    />

    <!-- Qr code details dialog -->
    <LinkDetailsDialog
      v-if="showDialog.details"
      :value="showDialog.details"
      :link="currentQrCode"
      @close="(needRefresh) => toggleDetailsDialog(false, null, needRefresh)"
    />

    <!-- Delete dialog -->
    <DeleteDialog
      v-if="showDialog.delete"
      :value="showDialog.delete"
      :title="$t('links.deleteDialog.title')"
      :isLoading="isLoadingBtnSave"
      @submit="deleteQrCode"
      @close="toggleDeleteDialog(false)"
    />
  </v-container>
</template>

<script>
import vuetifyDatatable from "@/mixins/vuetifyDataTable";
import formatter from "@/mixins/formatter";

export default {
  name: "LinksView",

  components: {
    DeleteDialog: () => import("@/components/Common/DeleteDialog"),
    LinkDetailsDialog: () => import("@/components/Links/LinkDetailsDialog"),
    BtnIconDelete: () => import("@/components/Common/Buttons/BtnIconDelete"),
    BtnIconEdit: () => import("@/components/Common/Buttons/BtnIconEdit"),
    BtnIconDetails: () => import("@/components/Common/Buttons/BtnIconDetails"),
    LinkMainDialog: () => import("@/components/Links/LinkMainDialog"),
    ButtonIconCopy: () => import("@/components/Common/Buttons/ButtonIconCopy"),
    IconCheckOrTimes: () => import("@/components/Common/IconCheckOrTimes"),
  },

  mixins: [vuetifyDatatable, formatter],

  data() {
    return {
      headers: [
        {
          text: this.$t("links.datatable.headers.label"),
          value: "label",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("links.datatable.headers.account"),
          value: "account",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("links.datatable.headers.product"),
          value: "product",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("links.datatable.headers.static_link"),
          value: "static_link",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("links.datatable.headers.dynamic_link"),
          value: "dynamic_link",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("links.datatable.headers.enabled"),
          value: "enabled",
          sortable: false,
          width: "5%",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "10%",
        },
      ],
      URL_API: {
        GET_ALL: "/links",
      },

      currentQrCode: null,

      showDialog: {
        addOrEdit: false,
        delete: false,
        details: false,
      },
      isLoadingBtnSave: false,
      savePaginationURL: true,
    };
  },

  created() {
    this.$store.commit("pageHead/setTitle", this.$t("links.pageTitle"));
  },

  methods: {
    deleteQrCode() {
      this.isLoadingBtnSave = true;
      this.$http
        .delete(`/links/${this.currentQrCode.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("links.deleteDialog.success")
          );
          this.toggleDeleteDialog(false, null, true);
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtnSave = false;
        });
    },

    toggleAddDialog(val, reloadData = false) {
      if (reloadData) this.searchTrigger();
      this.showDialog.addOrEdit = val;
    },

    toggleEditDialog(val, qrCode = null, reloadData = false) {
      if (reloadData) this.searchTrigger();
      this.currentQrCode = qrCode;
      this.showDialog.addOrEdit = val;
    },

    toggleDeleteDialog(val, qrCode = null, reloadData = false) {
      if (reloadData) this.searchTrigger();
      this.currentQrCode = qrCode;
      this.showDialog.delete = val;
    },

    toggleDetailsDialog(val, qrCode = null, reloadData = false) {
      if (reloadData) this.searchTrigger();
      this.currentQrCode = qrCode;
      this.showDialog.details = val;
    },
  },
};
</script>

<style scoped></style>
